import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

export default function Home({ data }) {
  return (
    <div>
      <div>Hello world!</div>
      <Img fixed={data.file.childImageSharp.fixed} />
    </div>
  )
}

export const pageQuery = graphql`
  query {
    file(relativePath: { eq: "calendar/Rodovid_blondy.jpg" }) {
      childImageSharp {
        fixed(width: 50) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
